<template>
  <div
    class="text-center"
  >
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          class="text-none"
          v-on="on"
        >
          <v-icon
            left
            size="16"
          >
            {{ icons.mdiFilter }}
          </v-icon>
          <span class="font-weight-regular">Filter by {{ tableName }}</span>
          <span
            v-if="tableName !== ''"
            class="font-weight-black"
            style="margin-left:3px"
          >
            {{ joiningWord }}
          </span>

          <span
            class="font-weight-black"
            style="margin-left:3px"
          > {{ filterLabel }}</span>
          <v-icon
            right
            size="16"
          >
            {{ icons.mdiChevronDown }}
          </v-icon>
        </v-btn>
      </template>

      <v-card>
        <date-filter
          v-if="showDateFilter"
          :default-filter="defaultDatePickerDate"
          @date-change="onDateChange"
        />
        <expiry-date-filter
          v-if="showExpiryDateFilter"
          :default-filter="defaultExpiryDatePickerDate"
          @expiry-date-change="onExpiryDateChange"
        />
        <customer-status-filter
          v-if="showCustomerStatusFilter || showConfigurationDetails"
          :initial-filters="filters.customerStatus"
          :show-service-status="showCustomerStatusFilter"
          :show-configuration-details="showConfigurationDetails"
          @status-change="onCustomerAccountStatusChange"
        />
        <ticket-assignment-filter
          v-if="showTicketAssignmentFilter"
          :initial-filter="filters.ticketAssignment"
          @assignment-change="onTicketAssignmentChange"
        />
        <ticket-status-filter
          v-if="showTicketStatusFilter"
          @status-change="onTicketStatusChange"
        />
        <ticket-priority-filter
          v-if="showTicketPriorityFilter"
          :initial-filters="filters.ticketPriority"
          @priority-change="onTicketPriorityChange"
        />
        <ticket-category-filter
          v-if="showTicketCategoryFilter"
          :initial-filters="filters.ticketCategory"
          @category-change="onTicketCategoryChange"
        />
        <payment-status-filter
          v-if="showPaymentStatusFilter"
          @status-change="onPaymentStatusChange"
        />
        <month-filter
          v-if="showMonthFilter"
          @month-change="onMonthChange"
        />
        <year-filter
          v-if="showYearFilter"
          @year-change="onYearChange"
        />
        <sms-status-filter
          v-if="showSmsStatusFilter"
          @status-change="onSmsStatusChange"
        />
        <payment-method-filter
          v-if="showPaymentMethodFilter"
          @status-change="onPaymentMethodChange"
        />
        <payment-analytics-category-filter
          v-if="showPaymentAnalyticsCategoryFilter"
          @payment-type-change="onPaymentTypeChange"
        />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="mt-2"
            small
            @click="onFilterButtonClick"
          >
            Filter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import {
  mdiFilter,
  mdiChevronDown,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import moment from 'moment'
import DateFilter from './DateFilter.vue'
import ExpiryDateFilter from './ExpiryDateFilter.vue'
import PaymentStatusFilter from './PaymentStatusFilter.vue'
import PaymentAnalyticsCategoryFilter from './PaymentAnalyticsCategoryFilter.vue'
import MonthFilter from './MonthFileter.vue'
import YearFilter from './YearFilter.vue'
import SmsStatusFilter from './SmsStatusFilter.vue'
import CustomerStatusFilter from './CustomerStatusFilter.vue'
import PaymentMethodFilter from './PaymentMethodFilter.vue'
import defaultMonthFilterDate from '@/mixins/defaultMonthFilterDate'
import TicketStatusFilter from './TicketStatusFilter.vue'
import TicketAssignmentFilter from './TicketAssignmentFilter.vue'
import TicketPriorityFilter from './TicketPriorityFilter.vue'
import TicketCategoryFilter from './TicketCategoryFilter.vue'

export default {
  components: {
    DateFilter,
    ExpiryDateFilter,
    PaymentStatusFilter,
    MonthFilter,
    YearFilter,
    SmsStatusFilter,
    CustomerStatusFilter,
    PaymentMethodFilter,
    PaymentAnalyticsCategoryFilter,
    TicketStatusFilter,
    TicketAssignmentFilter,
    TicketPriorityFilter,
    TicketCategoryFilter,
  },
  mixins: [defaultMonthFilterDate],
  props: {
    tableName: {
      type: String,
      default: '',
    },
    showDateFilter: {
      type: Boolean,
      default: true,
    },
    showExpiryDateFilter: {
      type: Boolean,
      default: false,
    },
    showMonthFilter: {
      type: Boolean,
      default: false,
    },
    showYearFilter: {
      type: Boolean,
      default: false,
    },
    showPaymentStatusFilter: {
      type: Boolean,
      default: false,
    },
    showSmsStatusFilter: {
      type: Boolean,
      default: false,
    },
    showCustomerStatusFilter: {
      type: Boolean,
      default: false,
    },
    showPaymentMethodFilter: {
      type: Boolean,
      default: false,
    },
    showPaymentAnalyticsCategoryFilter: {
      type: Boolean,
      default: false,
    },
    showConfigurationDetails: {
      type: Boolean,
      default: false,
    },
    showTicketStatusFilter: {
      type: Boolean,
      default: false,
    },
    showTicketAssignmentFilter: {
      type: Boolean,
      default: false,
    },
    showTicketPriorityFilter: {
      type: Boolean,
      default: false,
    },
    showTicketCategoryFilter: {
      type: Boolean,
      default: false,
    },
    defaultDatePickerDate: {
      type: String,
      default: '',
    },
    defaultExpiryDatePickerDate: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    initialFilters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      fav: true,
      menu: false,
      message: false,
      hints: true,
      icons: {
        mdiFilter,
        mdiChevronDown,
      },
      filterLabel: 'Last 30 days',
      filters: {},
    }
  },
  computed: {
    ...mapGetters(['companyName']),
    joiningWord() {
      return this.showExpiryDateFilter ? 'in' : 'from'
    },
  },
  watch: {
    label(newVal) {
      if (newVal !== '' && this.showMonthFilter) {
        this.filterLabel = this.defaultMonthDate.format('MMM YYYY')
      } else if (newVal !== '' && this.showYearFilter) {
        this.filterLabel = moment().format('YYYY')
      } else {
        this.filterLabel = newVal
      }
    },
    filterLabel(newLabel) {
      this.onFilterLabelChange(newLabel)
    },
    defaultDatePickerDate(defaultDatePickerDate) {
      let fromDate = moment().subtract(30, 'days').format('YYYY-MM-DD')
      let toDate = moment().format('YYYY-MM-DD')
      if (defaultDatePickerDate === 'Last 1 year') {
        this.filterLabel = this.defaultDatePickerDate
        fromDate = moment().subtract(1, 'year').format('YYYY-MM-DD')
        toDate = moment().format('YYYY-MM-DD')
      }
      this.filters.dates = {
        fromDate,
        toDate,
      }
      this.$emit('filter', this.filters)
    },
    defaultExpiryDatePickerDate(defaultExpiryDatePickerDate) {
      let fromDate = moment().format('YYYY-MM-DD')
      let toDate = moment().add(3, 'days').format('YYYY-MM-DD')
      if (defaultExpiryDatePickerDate === 'Next 3 days') {
        this.filterLabel = this.defaultExpiryDatePickerDate
        fromDate = moment().format('YYYY-MM-DD')
        toDate = moment().add(7, 'days').format('YYYY-MM-DD')
      }
      this.filters.expiryDates = {
        fromDate,
        toDate,
      }
      this.$emit('filter', this.filters)
    },
  },
  mounted() {
    if (this.initialFilters) {
      this.filters = this.initialFilters
    }
    if (this.showSmsStatusFilter) {
      this.filters.smsStatus = {
        delivered: false,
        sent: false,
        failed: false,
      }
    }
    if (this.showDateFilter) {
      let fromDate
      let toDate

      fromDate = moment().subtract(30, 'days').format('YYYY-MM-DD')
      toDate = moment().format('YYYY-MM-DD')

      if (this.defaultDatePickerDate === 'Last 7 days') {
        this.filterLabel = this.defaultDatePickerDate
        fromDate = moment().subtract(7, 'days').format('YYYY-MM-DD')
        toDate = moment().format('YYYY-MM-DD')
      }
      this.filters.dates = {
        fromDate,
        toDate,
      }
      this.$emit('filter', this.filters)
    }
    if (this.showExpiryDateFilter) {
      const fromDate = moment().format('YYYY-MM-DD')
      const toDate = moment().add(3, 'days').format('YYYY-MM-DD')
      this.filterLabel = 'Next 3 days'
      this.filters.expiryDates = {
        fromDate,
        toDate,
      }
      this.$emit('filter', this.filters)
    }
    if (this.showMonthFilter) {
      this.filterLabel = this.defaultMonthDate.format('MMM YYYY')
    }
    if (this.showYearFilter) {
      this.filterLabel = moment().format('YYYY')
    }
    if (this.showCustomerStatusFilter) {
      this.filterLabel = 'all customers'
    }
    if (this.showPaymentAnalyticsCategoryFilter) {
      this.filterLabel = 'all payments'
    }
    if (this.label) {
      this.filterLabel = this.label
    }
    this.onFilterLabelChange(this.filterLabel)
  },
  methods: {
    onDateChange(value) {
      this.filters.dates = value.filters
      this.filterLabel = value.label
    },
    onExpiryDateChange(value) {
      this.filters.expiryDates = value.filters
      this.filterLabel = value.label
    },
    onMonthChange(value) {
      this.filters.month = value.filters
      this.filterLabel = value.label
    },
    onYearChange(value) {
      this.filters.year = value.filters
      this.filterLabel = value.label
    },
    onPaymentStatusChange(value) {
      this.filters.paymentStatus = value
    },
    onCustomerAccountStatusChange(value) {
      this.filters.customerStatus = value.filters
      if (value.label !== 'all customers') {
        this.filterLabel = `customers with ${value.label}`

        return
      }
      this.filterLabel = value.label
    },
    onSmsStatusChange(value) {
      this.filters.smsStatus = value
    },
    onPaymentMethodChange(value) {
      this.filters.paymentMethod = value.filters
    },
    onPaymentTypeChange(value) {
      this.filters.paymentType = value.value
      this.filterLabel = value.label
    },
    onTicketStatusChange(value) {
      this.filters.ticketStatus = value.filters
    },
    onTicketAssignmentChange(value) {
      this.filters.ticketAssignment = value.filters
      this.filterLabel = value.label
    },
    onTicketPriorityChange(value) {
      console.log("🚀 ~ onTicketPriorityChange ~ value:", value)
      this.filters.ticketPriority = value.filters
    },
    onTicketCategoryChange(value) {
      console.log("🚀 ~ onTicketCategoryChange ~ value:", value)
      this.filters.ticketCategory = value.filters
    },
    onFilterLabelChange(newLabel) {
      if (((this.showDateFilter || this.showExpiryDateFilter) && this.tableName !== '') && (this.filters.dates || this.filters.expiryDates)) {
        const filterType = this.filters.dates ? 'dates' : 'expiryDates' // Determine which type of date filter is available
        const { fromDate, toDate } = this.filters[filterType]

        const formattedFromDate = this.$options.filters.formatDay(fromDate, 'YYYY-MM-DD')
        const formattedToDate = this.$options.filters.formatDay(toDate, 'YYYY-MM-DD')

        const dateRange = moment(fromDate).isSame(toDate, 'day')
          ? formattedFromDate
          : `${formattedFromDate} to ${formattedToDate}`

        const dateTypeLabel = filterType === 'expiryDates' ? 'Expiry Dates' : 'Dates' // Label for emitted value

        // Joining word logic based on showExpiryDateFilter
        const joiningWord = this.showExpiryDateFilter ? 'in' : 'from'

        const emittedValue = `${this.companyName} ${this.tableName} ${dateTypeLabel} ${joiningWord} ${dateRange}`
        this.$emit('filterLabelChange', this.toTitleCase(emittedValue))
      } else {
        const isExpiry = this.filters.showExpiryDateFilter
        const joiningWord = isExpiry ? 'in' : 'from' // Use 'in' for expiryDates, 'from' for others

        const emittedValue = `${this.companyName} ${this.tableName}${this.tableName !== '' ? ` ${joiningWord} ` : ''}${newLabel}`
        this.$emit('filterLabelChange', this.toTitleCase(emittedValue))
      }
    },
    toTitleCase(str) {
      return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
    },
    onFilterButtonClick() {
      this.menu = false
      this.$emit('filter', this.filters)
    },
  },

}
</script>
