<template>
  <div>
    <v-expansion-panels v-model="panel">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="body-1 font-weight-medium">
            Ticket Categories
          </p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card-text class="ml-2 pt-0">
            <v-row>
              <v-col
                v-for="category in ticketCategories"
                :key="category.id"
                cols="6"
                md="4"
              >
                <v-checkbox
                  v-model="filters[category.id]"
                  :label="category.display_name"
                  @change="onCheckboxChange"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    initialFilters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      panel: '',
      filters: {},
      ticketCategories: [],
      filterLabel: 'all categories',
    }
  },
  mounted() {
    this.fetchTicketCategories()
    if (this.initialFilters) {
      this.filters = { ...this.initialFilters }
    }
  },
  methods: {
    async fetchTicketCategories() {
      try {
        const response = await axios.get('/ticket-categories')
        this.ticketCategories = response.data
        this.initializeFilters()
        this.onCheckboxChange()
      } catch (error) {
        console.error('Failed to fetch ticket categories', error)
      }
    },
    initializeFilters() {
      this.ticketCategories.forEach(category => {
        if (!this.filters.hasOwnProperty(category.id)) {
          this.filters[category.id] = false
        }
      })
    },
    onCheckboxChange() {
      this.generateFilterLabel()
      const emittedFilters = {}
      this.ticketCategories.forEach(category => {
        emittedFilters[category.id] = !!this.filters[category.id]
      })
      const data = {
        filters: emittedFilters,
        label: this.filterLabel,
      }
      this.$emit('category-change', data)
    },
    generateFilterLabel() {
      const selectedLabels = this.ticketCategories
        .filter(category => this.filters[category.id])
        .map(category => category.display_name)

      if (selectedLabels.length === 0) {
        this.filterLabel = 'all categories'
      } else if (selectedLabels.length === 1) {
        this.filterLabel = selectedLabels[0]
      } else if (selectedLabels.length === 2) {
        this.filterLabel = `${selectedLabels[0]} and ${selectedLabels[1]}`
      } else if (selectedLabels.length === 3) {
        this.filterLabel = `${selectedLabels[0]}, ${selectedLabels[1]} and ${selectedLabels[2]}`
      } else if (selectedLabels.length > 3) {
        this.filterLabel = `${selectedLabels[0]}, ${selectedLabels[1]} and ${selectedLabels.length - 2} more`
      }
    },
  },
}
</script>
