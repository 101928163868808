<template>
  <div>
    <v-card-title class="pb-0 blue darken-4">
      <p class="body-1 font-weight-medium">
        Assignment Filter
      </p>
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text>
      <v-radio-group
        v-model="selectedFilter"
        row
        @change="onFilterChange"
      >
        <v-radio
          v-for="option in filterOptions"
          :key="option.value"
          :label="option.label"
          :value="option.value"
        ></v-radio>
      </v-radio-group>
    </v-card-text>
  </div>
</template>

<script>
export default {
  props: {
    initialFilter: {
      type: String,
      default: 'all_assignments',
    },
  },
  data() {
    return {
      selectedFilter: 'all',
      filterOptions: [
        { value: 'all_assignments', label: 'All Assignments' },
        { value: 'assigned_to_me', label: 'Assigned to Me' },
        { value: 'unassigned', label: 'Unassigned Tickets' },
      ],
    }
  },
  mounted() {
    this.selectedFilter = this.initialFilter
    this.onFilterChange()
  },
  methods: {
    onFilterChange() {
      const selectedOption = this.filterOptions.find(option => option.value === this.selectedFilter)

      const data = {
        filters: this.selectedFilter,
        label: selectedOption.label,
      }

      this.$emit('assignment-change', data)
    },
  },
}
</script>
