<template>
  <div>
    <v-expansion-panels v-model="panel">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="body-1 font-weight-medium">
            Ticket Status
          </p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card-text class="ml-2 pt-0">
            <v-row>
              <v-col
                v-for="status in ticketStatuses"
                :key="status.id"
                cols="6"
                md="4"
              >
                <v-checkbox
                  v-model="filters[status.id]"
                  :label="status.display_name"
                  @change="onCheckboxChange"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    initialFilters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      panel: '',
      filters: {},
      ticketStatuses: [],
      filterLabel: 'all tickets',
    }
  },
  mounted() {
    this.fetchTicketStatuses()
    if (this.initialFilters) {
      this.filters = { ...this.initialFilters }
    }
  },
  methods: {
    async fetchTicketStatuses() {
      try {
        const response = await axios.get('/ticket-statuses')
        this.ticketStatuses = response.data
        this.initializeFilters()
        this.onCheckboxChange()
      } catch (error) {
        console.error('Failed to fetch ticket statuses', error)
      }
    },
    initializeFilters() {
      this.ticketStatuses.forEach(status => {
        if (!this.filters.hasOwnProperty(status.id)) {
          this.filters[status.id] = false
        }
      })
    },
    onCheckboxChange() {
      this.generateFilterLabel()
      const emittedFilters = {}

      this.ticketStatuses.forEach(status => {
        emittedFilters[status.id] = !!this.filters[status.id]
      })

      const data = {
        filters: emittedFilters,
        label: this.filterLabel,
      }

      this.$emit('status-change', data)
    },
    generateFilterLabel() {
      const selectedLabels = this.ticketStatuses
        .filter(status => this.filters[status.id])
        .map(status => status.display_name)

      if (selectedLabels.length === 0) {
        this.filterLabel = 'all tickets'
      } else if (selectedLabels.length === 1) {
        this.filterLabel = selectedLabels[0]
      } else if (selectedLabels.length === 2) {
        this.filterLabel = `${selectedLabels[0]} and ${selectedLabels[1]}`
      } else if (selectedLabels.length === 3) {
        this.filterLabel = `${selectedLabels[0]}, ${selectedLabels[1]} and ${selectedLabels[2]}`
      } else if (selectedLabels.length > 3) {
        this.filterLabel = `${selectedLabels[0]}, ${selectedLabels[1]} and ${selectedLabels.length - 2} more`
      }
    },
  },
}
</script>
